import { CollectionType, DocumentType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import Button from '~/components/Button';
import CMSView from '~/components/View';
import { ReducersProps } from '~/reducers';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageEspaceCollections: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
}) => {
  const collections: DocumentType<CollectionType>[] | undefined = useSelector(
    (state: ReducersProps<any>) => state.cms.collections,
  );

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        {collections?.map(collection => (
          <li key={collection.id}>
            <Button
              to={`/espaces/${espace.id}/collections/${collection.id}/`}
              variant="link"
            >
              {collection.label}
            </Button>
          </li>
        ))}
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageEspaceCollections);
